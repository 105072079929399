<template>
	<div
		style=" padding: 10px; text-align: center; color: white"
	>
		<h1 style="margin-top: 50px;">{{ program.name }}</h1>
		<ul style="margin-top: 50px">
			<li
				v-for="(item, index) in items"
				:key="'item_' + index"

				style="border-bottom: 1px dot #ddd; padding: 10px; display: flex; justify-content: space-between; font-size: 1.3em"
			><span style="flex: 1">{{ item.name}}</span> <span style="flex:1">{{ item.size }}</span> <button style="flex: 1" @click="deleteItem(item)">데이터 삭제</button></li>
		</ul>

		<button
			@click="$emit('goBack')"

			style="font-size: 14px; border: 1px solid #ddd; padding: 10px; background-color: #eee; margin-top: 50px"
		>돌아가기</button>
	</div>
</template>

<script>
	export default {
		name: 'reset'
		, data: function(){
			return{
				program: {
					name: '로컬 데이터 리셋'
					, not_header: true
				}
				, items: [
					{ name: '계정 목록', key: 'account', size: 0}
					, { name: '지갑 목록', key: 'wallet', size: 0}
					, { name: '가입 정보', key: 'join_info', size: 0}
					, { name: '구매한 nft 목록', key: 'my_card', size: 0}
					, { name: '입출금 내역', key: 'history', size: 0}
					, { name: '댓글 신고 내역', key: 'comment_report', size: 0}
					, { name: '차단 목록', key: 'black_list', size: 0}
					, { name: '게시판 목록', key: 'board_config_list', size: 0}
					, { name: '가입 카르텔 목록', key: 'my_cartel', size: 0}
					, { name: '카르텔 멤버 목록', key: 'cartel_member', size: 0}
					, { name: '친구 목록', key: 'my_friends', size: 0}
					, { name: '선물 내역', key: 'history_present', size: 0}
					, { name: 'SEQ 목록', key: 'seq_id', size: 0}
					, { name: '게시물 목록', key: 'bbs', size: 0}
					, { name: '카르텔 목록', key: 'cartel', size: 0}

				]
			}
		}
		, methods: {
			deleteItem: async function(item){
				localStorage.removeItem(item.key)
				await this.getData()
				this.$bus.$emit('notify', { type: 'success', message: '삭제되었습니다'})
			}
			, getData: function(){
				this.items.forEach(function(item){
					let result = localStorage.getItem(item.key)
					if(result){
						item.size = JSON.parse(result).length
					}else{
						item.size = 0
					}
				})
			}
		}
		,created() {
			this.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>